import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import getColor from "@utils/getColor";
import { Container, Text } from "@atoms";

const CircleGrid = ({ circles }) => {
  const container = {
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: "20%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      y: "0%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <Container>
      <m.ul
        variants={container}
        initial="hidden"
        whileInView="show"
        className="flex flex-wrap items-center justify-center gap-6"
      >
        {circles?.map((circle, i) => {
          const { uid, icon, heading, descriptor } = circle;
          const color = getColor(i);
          return (
            <m.li
              key={uid}
              variants={item}
              className={classNames(
                "flex aspect-square max-w-[19rem] flex-grow items-center rounded-full p-8 md:flex-[1_0_calc(50%-2.5rem)] lg:flex-[1_0_calc(33.33%-1.75rem)]",
                {
                  "bg-teal": color === "teal",
                  "bg-pink text-white": color === "pink",
                  "bg-purple text-white": color === "purple",
                }
              )}
            >
              <div className="flex flex-col items-center gap-5 text-center">
                {!!icon[0]?.code && (
                  <div
                    className="svg-icon flex h-12 w-12 items-center justify-center text-white"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: icon[0]?.code }}
                  />
                )}
                <Text className="font-heading text-xl font-bold leading-tighter">
                  {heading}
                </Text>
                <Text variant="xs">{descriptor}</Text>
              </div>
            </m.li>
          );
        })}
      </m.ul>
    </Container>
  );
};

CircleGrid.defaultProps = {};

export default CircleGrid;
