const colors = ["purple", "teal", "pink", "pink", "purple", "teal"];

export const randomColor = type => {
  return colors[type][Math.floor(Math.random() * colors[type].length)];
};

const getColor = index => {
  // this makes the array loop back to the start
  const newIndex = index % colors.length;
  return colors[newIndex];
};

export default getColor;
